<template>
  <div class="myInfo">
    <div class="inner-1000">
      <div class="forget-wrap">
        <div class="l-title">通过手机号设置密码</div>
        <el-form label-width="0px" :model="loginForm3" :rules="loginRules3" ref="loginForm3" style="width:300px;">
          <el-form-item prop="phone">
            <el-input maxlength="11" type="text" v-model="loginForm3.phone" placeholder="请输入登录手机号"></el-input>
          </el-form-item>
		  <el-form-item prop="code2" class="password-item">
		    <el-input v-model="loginForm3.code2" placeholder="请输入验证码"></el-input>
		    <div class="l-forget" @click="getCheckCode()">
		      <img v-if="checkPic" style="width:100%" :src="checkPic"/>
		    </div>
		  </el-form-item>
          <el-form-item prop="code" class="password-item">
            <el-input maxlength="10" v-model="loginForm3.code" placeholder="请输入短信验证码"></el-input>
            <div class="l-forget" v-show="showTimer">重新发送({{ time }})</div>
            <div class="l-forget" v-show="!showTimer" @click="getCode('setPwd', loginForm3.phone,loginForm3.code2,loginForm3.uuid)">
              获取验证码
            </div>
          </el-form-item>
          <div class="l-title">设置新密码</div>
          <el-form-item prop="pwd">
            <el-input maxlength="20" type="password" v-model="loginForm3.pwd" placeholder="请输入新密码"></el-input>
          </el-form-item>
          <el-form-item prop="rePwd">
            <el-input
        						   maxlength="20"
              type="password"
              v-model="loginForm3.rePwd"
              placeholder="请再次输入密码"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button class="login-btn" type="primary" @click="setPwd('loginForm3')">确认</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
	import md5 from 'js-md5';
import { regionData, CodeToText } from 'element-china-area-data';
export default {
  name: '',
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.loginForm3.Repwd !== '') {
          this.$refs.loginForm3.validateField('Repwd');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      console.log(value, 'rePwd');
      console.log(rule, 'rule');
      console.log(this.loginForm3.pwd, 'pwd');
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.loginForm3.pwd) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      time: 60,
      showTimer: false,
	  checkPic:'',
	  uuid:'',
      loginForm3: {
        phone: '',
        code: '',
        pwd: '',
        rePwd: '',
		code2:'',
		uuid:''
      },
      loginRules3: {
        phone: [
          { required: true, message: '请输入正确的手机号', trigger: 'blur' },
          { pattern: /^1[3456789]\d{9}$/, message: '手机号格式不正确' }
        ],
        code: [
          {
            required: true,
            message: '请输入验证码',
            trigger: 'blur'
          }
        ],
        pwd: [
          { validator: validatePass, trigger: 'blur' },
          {
            min: 6,
            max: 20,
            message: '请输入6-20位密码，区分大小写，不能使用空格！',
            trigger: 'blur'
          }
        ],
        rePwd: [{ validator: validatePass2, trigger: 'blur' }]
      }
    };
  },
  computed: {
    editor() {
      return this.$refs.myQuillEditor.quill;
    }
  },
  created() {
    // 获取个人信息
    //this.getUserInfo();
    //this.getFirstSubject();
	this.getCheckCode();
  },
  mounted() {},
  watch: {},
  methods: {
	getCheckCode(){
	  this.get('/biz/code', {},(e)=>{
		  if(e.code==200){
			this.loginForm3.uuid=e.uuid
			  this.checkPic='data:image/png;base64,'+e.img
		  }
		  
	  })
	},
	// 获取验证码
	getCode(type, phone,yzCode,uuid) {
	  if(!phone){
	  	this.$.ui.toast('请输入手机号')
	  	return;
	  }
	  if(!yzCode){
	  	this.$.ui.toast('请输入验证码')
	  	return;
	  }
	  this.showTimer = true;
	  this.time = 60;
	  this.post(
		
		'/biz/sms/checkCode?mobile='+phone+'&smsType='+type,
		{
		  uuid:uuid,
		  code:yzCode
		},
		e => {
			this.getCheckCode();
		  if (e.code === 200) {
			  this.$.ui.toast('验证码已发送')
			const setI = setInterval(() => {
			  if (this.time <= 0) {
				clearInterval(setI);
				this.showTimer = false;
				return;
			  }
			  this.time -= 1;
			}, 1000);
		  } else {
			  
			  this.showTimer = false;
			  this.time = 60;
			this.$.ui.toast(e.msg);
		  }
		}
	  );
	},
	setPwd(formName) {
	  this.$refs[formName].validate(valid => {
		if (valid) {
		  const newPwd = md5(this.loginForm3.pwd);
		  this.post(
			'/biz/teacher/personal/set/pwd',
			{ checkCode: this.loginForm3.code, newPwd: newPwd },
			e => {
			  if (e.code == 200) {
				this.$.ui.toast('修改成功');
				
				setTimeout(() => {
				  this.user = {};
				  this.loginForm3 = {
					phone: '',
					code: '',
					pwd: '',
					rePwd: ''
				  };
				  this.href('/');
				}, 1000);
			  } else {
				this.$.ui.toast(e.msg);
			  }
			}
		  );
		} else {
		}
	  });
	}
    
  },
  components: {}
};
</script>

<style lang="less">
.forget-wrap {
  padding: 30px;
  width: 385px;
  // height: 468px;
  background: #ffffff;
  border-radius: 10px;
  .l-title {
    display: flex;
    flex-direction: row;
    padding-bottom: 22px;
    height: 35px;
    font-size: 18px;
    font-family: SF Pro Text;
    font-weight: 600;
    line-height: 21px;
    color: #323233;
  }
  .el-input input {
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0px;
  }
  .login-btn {
    width: 321px;
    height: 48px;
    background: #007bff;
    border-radius: 10px;
    margin-top: 10px;
  }
  .l-text {
    display: block;
    width: 321px;
    text-align: center;
    height: 20px;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    color: #969699;
    opacity: 0.5;
    padding-bottom: 13px;
    &.desc {
      text-align: left;
      font-size: 12px;
      font-weight: normal;
      opacity: 1;
    }
  }
  .l-icon {
    width: 321px;
    text-align: center;
    height: 26px;
    background: url(~@/assets/weixin.png) no-repeat center center;
    background-size: 26px 26px;
  }
  .l-tips {
    padding-top: 41px;
    width: 321px;
    text-align: center;
    height: 17px;
    font-size: 12px;
    font-weight: 500;
    line-height: 17px;
    color: #bebebe;
    span {
      padding: 0 8px;
      height: 17px;
      font-size: 12px;
      font-weight: 500;
      line-height: 17px;
      color: #46688c;
    }
  }
  .password-item {
    position: relative;
    .l-forget {
      cursor: pointer;
      position: absolute;
      right: 20px;
      top: 10px;
      width: 75px;
      text-align: right;
      border-left: 2px solid #d4d4d9;
      height: 17px;
      font-size: 12px;
      font-weight: 500;
      line-height: 17px;
      color: #89898c;
    }
  }
}
</style>
